<template>
	<div
		class="mt-30 text-left flex-row"
	>
		<MypageSide
			:program="program"
			:user="user"
		></MypageSide>

		<div class="flex-1 bg-white pa-24 radius-20">

			<div class="under-line-bbb pb-16 flex-row justify-space-between items-center">
				<h3 class="flex-1 size-px-20">{{	program.title }}</h3>
				<h6>마피아 서비스의 공지사항을 확인하세요.</h6>
			</div>

			<ul class="mt-30">
				<li
					v-for="item in items"
					:key="item.notice_number"
					class="under-line mb-16 pa-10 flex-row justify-space-between items-center cursor-pointer"
					@click="$router.push({name : 'bbs_detail', query : { notice_number : item.notice_number, notice_title : item.notice_title, notice_reg_date : item.registdate, notice_new_fg : item.new_reg_fg	}})"
				>
					<div class="color-333 font-weight-500">
						{{ item.notice_title }}
						<span class=" color-red" v-if="item.new_reg_fg == 'Y'">NEW</span>
					</div>
					<div class="color-888 size-px-14">{{ item.registdate }}</div>
				</li>
			</ul>

<!--			<div class="pagination" v-if="!items.page_info">
				<a href="#">&laquo;</a>
				<a href="#">1</a>
				<a href="#" class="active">2</a>
				<a href="#">3</a>
				<a href="#">4</a>
				<a href="#">5</a>
				<a href="#">6</a>
				<a href="#">&raquo;</a>
			</div>-->

			<Pagination
				:options="item_search"
				@click="getSearchNoticeList"
			></Pagination>
		</div>

	</div>
</template>

<script>
import MypageSide from "@/view/Mypage/mypage-lnb";
import Pagination from "@/components/Pagination";
export default {
	name: "bbs"
	, components: {MypageSide, Pagination}
	, props: ['user']
	, data: function () {
		return {	
			program: {
				name: '커뮤니티'
				, title: '공지사항'
				, b_code: this.$route.params.b_code
				, type: 'mypage'
				, bg_contents: 'bg-gray01'
			}
			, items: []
			, currentPage: 1
			, page_unit : 1
			, page_tot_count : 1
			, item_search: {
				page: 1
				, list_cnt: 10
			}
		}
	}

	, computed: {
		pages() {

			let endPage = this.page_unit * 5
			let startPage = endPage - 4

			if (this.page_tot_count < endPage) {
				endPage = (endPage - (endPage - this.page_tot_count))
			}

			let pages = []
			for (let i = startPage; i <= endPage; i++) {
				pages.push(i)
			}
			return pages
		}
		, canDecrement() {
			if (this.page_unit <= 1) {
				return true
			}
			return false
		}

		, canIncrement() {
			if (this.page_tot_count < (this.page_unit * 5)) {
				return true
			}
			return false
		}

	}

	,	watch: {
		currentPage() {
			this.$nextTick(() => {
				// 페이지 변경시에는 데이터를 업데이트 합니다.
				// 이곳에서 API를 호출하여 새로운 데이터를 가져올 수 있습니다.
				this.$log.console("Page changed: ", this.currentPage);
				this.getNotice(this.currentPage)
			});
		},
	}

	, methods: {

		getNotice: async function () {
			try {
				this.$bus.$emit('on', true)

				const result = await this.$Request({
					method: 'post'
					, url: this.$api_url.api_path.get_notice_list
					, data: {
						member_number: this.user.member_number
						, page_number: this.item_search.page
						, pagerecnum: this.item_search.list_cnt
					}
					, type: true
				})
				if (result.success) {
					this.items = result.data.notice_mttrs_list
					this.$set(this.item_search, 'total_count', result.data.page_info.page_tot_count)
				} else {
					throw result.message
				}
			} catch (e) {
				this.$log.console(e)
				this.$bus.$emit('notify', {type: 'error', message: e})
			} finally {
				this.$bus.$emit('on', false)
			}
		}

		, getSearchNoticeList: function(page){
			if(page){
				this.item_search.page = page
			}

			this.getNotice()
		}

	}

	, created() {
		this.$emit('onLoad', this.program)
		this.getNotice()
	}
}
</script>

<style scoped>
.type-bbs .section_wrap {
	display: flex;
	flex-wrap: wrap;
}

.type-bbs h6 {
	line-height: 260%;
	font-weight: 400;
}

.type-bbs li.under-line {
	padding-bottom: 16px;
}

.type-bbs .title {
	border-bottom: 1px solid #b2b2b2;
	box-sizing: content-box;
	display: flex;
	align-items: center;
	gap: 10px;

}

.type-bbs .text-gray {
	color: var(--light-Gray03);
}

.type-bbs .text-red {
	color: var(--red);
}

/* .type-bbs .text-black {
	color: var(--black);
} */

.detail_title {
	width: 35px;
	height: 35px;
	line-height: 200%;
	background-color: #e5e5e5;
	border-radius: 20px
}

.btn-inline {
	background-color: white;
	color: var(--blue02);
	border: 2px solid var(--blue02); /* Green */
}

.btn-inline:hover {
	background-color: var(--blue02) !important;
	color: white;
}


.type-bbs	.pagination {
	display: inline-block;
}

.type-bbs	.pagination button {
	color: black;
	float: left;
	padding: 8px 16px;
	text-decoration: none;
	transition: background-color .3s;
	border: 1px solid #ddd;
	border-radius: 8px;
	margin: 0 4px;
}

.type-bbs .pagination button.active {
	background-color: var(--blue02);
	color: white;
	border: 2px solid var(--blue02);
}

.type-bbs .pagination button:hover:not(.active) {background-color: #ddd;}

</style>